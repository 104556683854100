import type { PropsWithChildren } from 'react'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Header } from './header'
import type { FooterQuery } from './footer'
import { Footer } from './footer'
import RegionSelector, { countries } from '@/components/region-selector'

interface PageProps extends PropsWithChildren {
  footerData: FooterQuery
  classNames?: string
  country?: string
  domain: string
}

export default function Page({
  children,
  footerData,
  classNames,
  country,
  domain,
}: PageProps) {
  const [loading, setLoading] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [countryName, setCountryName] = useState<string | null>(null)

  useEffect(() => {
    setLoading(false)

    // Logic to determine if the popup should be shown
    // only AU, GB, NZ need to be checked, Default is AU site
    // If the user is from AU or NZ and is on the UK site it should read
    // "Are you visiting from outside The United Kingdom?"
    if ((country === 'AU' || country === 'NZ') && domain.includes('.co.uk')) {
      setCountryName('The United Kingdom')
      setShowPopup(true)
      // If the user is from the UK and is on the AU site it should read
      // "Are you visiting from outside Australia?"
    } else if (country === 'GB' && domain.includes('.com.au')) {
      setCountryName('Australia')
      setShowPopup(true)
    }
  }, [country, domain])

  const handleCountrySelect = (selectedCountry: string) => {
    window.location.href =
      countries.find((c) => c.id === selectedCountry)?.url ??
      'www.writinglegends.com.au'
  }

  if (loading) {
    return null // stops the page from flashing during render cycle until the cookie check is complete
  }

  return (
    <div
      className={twMerge(
        'prose prose-base flex min-h-screen max-w-none flex-col max-sm:prose-sm',
        classNames,
      )}
    >
      {showPopup && countryName && (
        <RegionSelector
          countryName={countryName}
          onCountrySelect={handleCountrySelect}
        />
      )}

      <Header />
      {children}
      <Footer query={footerData} />
    </div>
  )
}
