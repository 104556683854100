import { faLock, faPlay, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { Menu } from '@headlessui/react'
import { twJoin } from 'tailwind-merge'

export function Header() {
  return (
    <>
      <div className="mb-auto prose-h2:font-bold prose-h2:text-white prose-a:text-xl prose-a:font-bold prose-a:text-white prose-a:text-shadow-lg">
        <div className="bg-wl-blue-regatta-700 py-2 sm:px-6">
          <div className="content-container flex-row space-x-8 md:justify-end">
            <Link
              className="hover:text-wl-orange-500 "
              href="https://www.writinglegends.com/signin"
            >
              <FontAwesomeIcon icon={faLock} className="pr-2 drop-shadow-md" />
              <span>LOGIN</span>
            </Link>
            <Link href="/contact-us/" className="hover:text-wl-orange-500">
              <span>SIGN UP</span>
              <FontAwesomeIcon icon={faPlay} className="pl-2 drop-shadow-md" />
            </Link>
          </div>
        </div>
        <div className="blue-scribbles-background">
          <div className="content-container flex-row justify-center sm:px-8 md:justify-between">
            <Image
              src="/images/logos/wl-logo-stacked.svg"
              height={300}
              width={300}
              alt="wl-logo-stacked"
              className="max-w-[250px] object-fill md:max-w-[300px]"
            />
            <div className="hidden flex-col items-end sm:flex">
              <div className="flex flex-row">
                <div className="flex items-end">
                  <Image
                    src="/images/inkpot.png"
                    height={64}
                    width={64}
                    alt="inkpot"
                    className="object-fill"
                  />
                </div>
                <div className="">
                  <Image
                    src="/images/mascots/mascot_02.png"
                    height={256}
                    width={256}
                    alt="mascot"
                    className="-scale-x-100 transform object-fill"
                  />
                </div>
              </div>
              <h2 className="invisible mt-2 italic text-shadow-lg sm:visible">
                A Community Of Writers In Every Room.
              </h2>
            </div>
          </div>
        </div>
        <div className="bg-wl-orange-500 py-2">
          <div className="content-container flex-row space-x-8 prose-a:hover:text-white sm:px-8 md:justify-start md:space-x-16">
            <Link href="/">Home</Link>
            <Link href="/about-us">About Us</Link>
            <Menu
              as="div"
              className="not-prose relative z-50 inline-block text-left text-white text-shadow-sm"
            >
              <Menu.Button className="text-xl font-bold text-white text-shadow-lg">
                Help
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className="ml-2 rotate-90 drop-shadow"
                />
              </Menu.Button>
              <Menu.Items className="text-md absolute right-0 mt-2 w-56 origin-top-right border border-amber-700 bg-wl-orange-500 shadow-lg md:left-0">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href="/contact-us"
                      className={twJoin(
                        'flex w-full items-center px-2 py-2',
                        active && 'bg-amber-500',
                      )}
                    >
                      Contact Us
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href="/faq"
                      className={twJoin(
                        'flex w-full items-center px-2 py-2',
                        active && 'bg-amber-500',
                      )}
                    >
                      FAQs
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href="/technical-requirements"
                      className={twJoin(
                        'flex w-full items-center px-2 py-2',
                        active && 'bg-amber-500',
                      )}
                    >
                      Technical Requirements
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </>
  )
}
