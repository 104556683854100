import Head from 'next/head'
import type { PropsWithChildren } from 'react'

export interface PageMetadataProps extends PropsWithChildren {
  title?: string | undefined | null
  description?: string | undefined | null
  keywords?: (string | null)[] | null | undefined
}

export default function PageMetadata({
  title,
  description,
  keywords,
  children,
}: PageMetadataProps) {
  const pageTitle = title ?? 'Writing Legends Marketing'
  const pageDescription =
    description ??
    "Unlock students' writing confidence with a comprehensive and engaging program for students in years 1–6. Develop essential skills, receive instant feedback and see clear progress!"
  const pageKeywords = keywords ?? [
    'childhood education',
    'reading',
    'writing',
    'education',
  ]

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="keywords" content={pageKeywords.join(', ')} />
      {children}
    </Head>
  )
}
