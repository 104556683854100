import { useState } from 'react'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react'

interface RegionSelectorProps {
  countryName: string
  onCountrySelect: (country: string) => void
}

export const countries = [
  { id: 'AU', name: 'Australia', url: 'https://www.writinglegends.com.au' },
  { id: 'GB', name: 'United Kingdom', url: 'https://www.writinglegends.co.uk' },
]

const RegionSelector = ({
  countryName,
  onCountrySelect,
}: RegionSelectorProps) => {
  const [selected, setSelected] = useState<
    { id: string; name: string } | undefined
  >(undefined)

  const handleSetCountry = () => {
    if (!selected) return
    onCountrySelect(selected.id)
  }

  return (
    <div
      className={`not-prose inline-flex w-full items-center justify-start bg-blog-dusty-black-300 px-4 py-4 sm:justify-center`}
    >
      <div className="flex w-full flex-col items-center justify-center space-y-4 sm:max-w-4xl sm:flex-row sm:space-x-4">
        <div className="w-full text-wrap text-white lg:min-w-fit">
          <p>Are you visiting from outside {countryName}?</p>
          <p>
            Visit your regional site for your relevant pricing, curriculum and
            product information.
          </p>
        </div>
        <div className="sm:max-w-1/4 flex w-full flex-row justify-center space-x-4">
          <Listbox value={selected} onChange={setSelected}>
            <div className="relative w-full min-w-52">
              <ListboxButton className="relative w-full min-w-fit cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:pl-3 sm:pr-10 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {selected ? selected.name : 'Select Your Country'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </ListboxButton>

              <ListboxOptions
                transition
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
              >
                {countries.map(({ id, name }) => (
                  <ListboxOption
                    key={id}
                    value={{ id, name }}
                    className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                  >
                    <span className="block truncate font-normal group-data-[selected]:font-semibold">
                      {name}
                    </span>

                    <span className="absolute inset-y-0 left-0 flex items-center pl-1.5 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="black"
                        className="h-5 w-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </div>
          </Listbox>
          <button
            className="block rounded-md border-0 bg-[#009CE4] px-8 font-bold text-white transition-colors hover:bg-[#355F9D] sm:text-sm sm:leading-6"
            onClick={handleSetCountry}
          >
            <span>Go</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegionSelector
