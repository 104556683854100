import Script from 'next/script'
import React from 'react'

const jsUrlPath = process.env['NEXT_PUBLIC_NETREVIEWS_JS_URL_PATH'] ?? '/'
const id = process.env['NEXT_PUBLIC_NETREVIEWS_ID'] ?? ''
const srcPath = process.env['NEXT_PUBLIC_NETREVIEWS_SRC_PATH'] ?? '/'

const baseUrl = new URL('https://cl.avis-verifies.com')
const dataUrl = new URL(jsUrlPath, baseUrl)
const scriptUrl = new URL(srcPath, baseUrl)

export function NetReviewsWidget() {
  return (
    <>
      <meta
        className="netreviewsWidget"
        id={id}
        data-jsurl={dataUrl.toString()}
      />
      <Script src={scriptUrl.toString()} strategy="lazyOnload" />
    </>
  )
}
